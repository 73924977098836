.image {
	$label-offset: 1.25em;
	position: relative;

	&__label {
		text-shadow: 0 0 5px rgba(0, 0, 0, 0.95), 0 0 10px rgba(0, 0, 0, 0.75);
		position: absolute;
		bottom: $label-offset;
		right: $label-offset;
		color: $white;
		line-height: 1;
		font-family: $secondary-font;
		text-transform: uppercase;
		z-index: 1000;
		text-align: right;
		@include photoshop-to-css-font(30, 70, 2);

		&--top {
			top: $label-offset;
			bottom: auto;
		}

		&--left {
			text-align: left;
			left: $label-offset;
			right: auto;
		}

		&--small {
			@include photoshop-to-css-font(26, 70, 2);
		}

		&--large {
			@include photoshop-to-css-font(34, 70, 2);
		}

		&--lightbox {
			padding: 1.5rem;
		}
	}
}
