.hero {
	$self: &;
	position: relative;

	@include media-breakpoint-down(sm) {
		overflow: hidden;
	}

	&__headline {
		position: relative;
		z-index: 2;
		margin: 0;
		line-height: 1;
		font-family: $accent-font;
		font-weight: 700;
		@include photoshop-to-css-font(255, 0, 2);

		@include media-breakpoint-down(lg) {
			@include photoshop-to-css-font(200, 0, 2);
		}

		@include media-breakpoint-down(md) {
			@include photoshop-to-css-font(150, 0, 2);
		}

		@include media-breakpoint-down(sm) {
			@include photoshop-to-css-font(100, 0, 2);
		}
	}

	&__subheadline {
		position: relative;
		z-index: 2;
		margin: 0;
		text-transform: uppercase;
		line-height: 1;
		font-family: $header-font;
		@include photoshop-to-css-font(85, 125, 2);

		@include media-breakpoint-down(md) {
			@include photoshop-to-css-font(52, 125, 2);
		}
	}

	&__introduction,
	&__headline {
		position: relative;
		z-index: 2;
	}

	&__introduction {
		margin: 2rem 0 0;
		padding: 0 18vw;
		color: $white;
		@include photoshop-to-css-font(40, 24, 2);
	}

	&__slides {
		&__dots {
			display: flex !important;
			background-color: $white;
			font-family: $secondary-font;
			@include reset-list;

			@include media-breakpoint-down(md) {
				display: block !important;
			}

			li {
				cursor: pointer;
				flex: 1;
				padding: 1.5em 0;
				transition: all 0.4s ease;
				text-align: center;
				text-transform: uppercase;
				@include photoshop-to-css-font(28, 75, 2);

				&:hover {
					color: $highlight-color;
				}

				&.slick-active {
					color: $primary-color;
				}

				+ li {
					border-left: 1px solid $border-color;

					@include media-breakpoint-down(md) {
						border-left: none;
						border-top: 1px solid $border-color;
					}
				}
			}
		}
	}

	&__slide {
		height: 65vh;
		min-height: 550px;
		max-height: 800px;
		z-index: 1;
		background-position: center;
		@include background-cover;

		@include media-breakpoint-down(lg) {
			height: 450px;
			min-height: 0;
		}

		@include media-breakpoint-down(md) {
			height: 325px;
		}
	}

	&__caption {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		position: relative;
		z-index: 2;
		color: $white;

		&__buttons {
			margin: calc($section-padding / 2) 0 0;

			@include media-breakpoint-down(md) {
				margin-top: $section-padding-sm;
			}
		}
	}

	&__image {
		height: 100%;
		width: 100%;
		max-width: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 0;

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	&__pager {
	}

	&__breadcrumbs-bar {
		background-color: $white;
	}

	&__breadcrumbs {
	}

	&__offer {
		background-color: $green;

		.container {
			padding: 0 8vw;
			color: $white;
			text-align: center;

			h1,
			h2,
			h3,
			h4,
			h5 {
				text-transform: uppercase;
				font-family: $primary-font;
				line-height: 1.2em;
			}

			h2 {
				@include photoshop-to-css-font(68, 70, 2);
			}

			p {
				font-size: 1.3rem;
			}

			> * {
				&:first-child {
					padding-top: calc($section-padding / 2);
				}

				&:last-child {
					padding-bottom: calc($section-padding / 2);
				}
			}
		}
	}

	&__box {
		@include make-row;
	}

	&__container {
		margin: 0 auto;
		@include make-container-max-widths;
	}

	&--home {
	}

	&--page,
	&--archive {
		position: relative;

		@include media-breakpoint-down(sm) {
		}

		#{$self}__caption {
			color: $white;
			padding: $section-padding 0;
			@include background-cover;

			@include media-breakpoint-down(md) {
				padding: $section-padding-md 0;
			}
		}

		#{$self}__caption-box {
			padding: 0 5vw;

			@include media-breakpoint-down(md) {
				padding: 0 10vw;
			}

			@include media-breakpoint-down(sm) {
				padding: 0;
			}
		}

		#{$self}__headline {
			font-family: $primary-font;
			text-transform: uppercase;
			font-weight: normal;
			@include photoshop-to-css-font(175, 70, 2);

			@include media-breakpoint-down(lg) {
				@include photoshop-to-css-font(150, 0, 2);
			}

			@include media-breakpoint-down(md) {
				@include photoshop-to-css-font(115, 0, 2);
			}

			@include media-breakpoint-down(sm) {
				@include photoshop-to-css-font(90, 0, 2);
			}
		}

		#{$self}__subheadline {
			margin: 0 0 1em;
			font-family: $secondary-font;
			text-transform: uppercase;
			font-weight: normal;
			line-height: 1.5em;
			@include photoshop-to-css-font(32, 125, 2);

			a {
				color: $white;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&--overlay {
		&::after {
			content: "";
			background-color: rgba(#333239, 0.5);
			position: absolute;
			@include to-edges;
		}
	}

	.slick-list,
	.slick-track {
		height: 100%;
	}
}
