.section {
	$section: &;

	&--split {
		$split: &;
		padding: 0;
		position: relative;
		overflow: hidden;
		@include background-cover;

		#{$section}__article {
			order: 6;
			// padding-top: $section-padding;
			// padding-bottom: $section-padding;
			z-index: 10;
			@include make-col-ready;
			@include make-col(6);

			&--left,
			&--right {
				@include media-breakpoint-down(md) {
					@include make-col(12);
					@include py($section-padding-md);
				}

				@include media-breakpoint-down(sm) {
					@include py($section-padding-sm);
				}
			}

			&--left {
				@include media-breakpoint-up(md) {
					padding-right: $grid-gutter-width * 2;
				}
			}

			&--right {
				@include media-breakpoint-up(md) {
					padding-left: $grid-gutter-width * 2;
					margin-left: auto;
					animation-delay: 0.25s;
				}
			}
		}

		#{$section}__headline {
			&::after {
				//left: -5vw;
				justify-content: start;
				white-space: nowrap;
			}
		}

		#{$section}__sidebar {
			$sidebar: #{$section}__sidebar;

			&__photo {
				position: absolute;
				@include to-edges;
				@include background-cover;

				@include media-breakpoint-down(md) {
					position: static;
					height: 100%;
				}
			}

			&--card {
				@include make-col-ready;
				@include make-col(4);
				// @include py($section-padding);

				@include media-breakpoint-down(md) {
					order: 12 !important;
					@include make-col(12);
					@include pt(0);
					@include pb(calc($section-padding-md / 2));
				}
			}

			&--video {
				@include make-col-ready;
				@include make-col(6);

				@include media-breakpoint-down(md) {
					@include make-col(12);
				}
			}

			&--photo {
				order: 12;
				z-index: 5;
				position: absolute;
				@include to-edges;

				@include media-breakpoint-down(md) {
					position: static;
					height: 350px;
					order: 1;
				}

				&#{$sidebar}--left {
					right: 50%;
				}

				&#{$sidebar}--right {
					left: 50%;
				}
			}

			&--right {
				order: 12;
				animation-delay: 0.25s;
			}

			&--left {
				order: 1;
			}
		}

		&#{$section}--sidebar-card {
			#{$section}__article {
				@include make-col-expand;
			}

			.box {
				align-items: center;
			}
		}
	}
}
