/* Footer */

.basement {
	$self: &;

	&__logo-bar {
		background-color: rgba($black, 0.05);
	}

	&__content {
		padding: calc($section-padding / 2) 0;
		font-family: $secondary-font;
		text-transform: uppercase;
	}

	&__header {
		color: $text-color;
		font-family: $secondary-font;
		font-weight: bold;
		@include photoshop-to-css-font(28, 75, 2);
	}

	&__detail {
		$detail: #{$self}__detail;
		@include make-col-ready;
		@include make-col-auto;
		@include photoshop-to-css-font(28, 75, 2);

		+ #{$detail} {
			margin-top: $grid-gutter-width;
		}
	}

	&__widgets {
		margin-left: auto;
		@include make-col-ready;
		@include make-col-auto;

		@include media-breakpoint-down(md) {
			margin-top: 1rem;
			@include make-col(12);
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			right: 100%;
			width: 1px;
			background-color: $border-color;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	&__widget {
		@include make-col-ready;
		@include make-col-auto;
		@include px(calc($section-padding / 2));

		@include media-breakpoint-down(lg) {
			@include px(calc($section-padding-md / 2));
		}

		@include media-breakpoint-down(md) {
			@include make-col-ready;
			@include make-col-auto;
		}

		@include media-breakpoint-down(sm) {
			width: 100% !important;
			margin-bottom: 1rem;
		}

		ul {
			@include reset-list;
			@include photoshop-to-css-font(25, 75, 2);
		}

		.menu {
			> li {
				> a {
					@extend #{$self}__header;
				}
			}
		}

		.sub-menu {
			li {
				margin-top: 1em;
			}
		}
	}

	&__newsletter {
		background-color: $white;
		padding: $grid-gutter-width 0;
	}

	&__copyright {
		text-align: center;
		border-bottom: 3px solid $text-color;
		text-transform: uppercase;
		padding: 15px 0;
		font-family: $secondary-font;
		@include photoshop-to-css-font(19, 35, 2);

		ul {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			@include reset-list;

			> li {
				+ li {
					&::before {
						content: "\2022";
						padding: 0 1em;
					}
				}
			}
		}

		&,
		a {
			color: $text-color;
		}
	}
}
