.archive {
	$archive: &;

	&__list {
		$list: &;
		display: flex;
		width: 100%;
		@include reset-list;
		@include make-row;
		@include mx(0);
		//margin-left: -$grid-gutter-width / 2;
		//margin-right: -$grid-gutter-width / 2;

		&__item {
			margin: 1em 0;
			@include make-col-ready;
			@include make-col(4);
			//padding-left: $grid-gutter-width / 2;
			//padding-right: $grid-gutter-width / 2;
			//flex: 1 0;
			//max-width: 33.333%;

			@for $i from 0 to 20 {
				&:nth-child(#{$i}) {
					animation-delay: ($i - 1) * 0.2s;
				}
			}

			@include media-breakpoint-down(lg) {
				@include make-col(6);
			}

			@include media-breakpoint-down(sm) {
				@include make-col(12);
			}
		}

		&--grid {
			&#{$list}--single-row {
				align-items: flex-start;
			}
		}

		&--two-column {
			#{$list}__item {
				@include make-col(6);

				@include media-breakpoint-down(md) {
					@include make-col(12);
				}
			}
		}

		&--one-column {
			#{$list}__item {
				@include make-col(12);

				&:nth-child(even) {
					.callout {
						@extend .callout--even;
					}
				}
			}
		}
	}

	&__search {
		@include make-col-ready;
		@include make-col-auto;
		@include ml(auto);
	}

	&__pagination {
		display: flex;
		margin: 2rem 0 0;
		@include make-col-ready;
		@include make-col-expand;
	}

	&__paginate {
		&--load-more {
			margin: 0 auto;
		}
	}

	&__searchbox {
		margin: 0 auto calc($section-padding / 3);
		width: 800px;
		max-width: 100%;
	}

	&__noresult {
		h2 {
			margin: 0;
			line-height: 1.2em;
		}
	}

	&__filters {
		@include make-col-ready;
		@include make-col(3);

		@include media-breakpoint-down(md) {
			@include make-col(12);
			@include mb($grid-gutter-width);
		}

		+ #{$archive}__list {
			#{$archive}__list__item {
				@include mt(0);
				@include mb($grid-gutter-width);
			}

			&#{$archive}__list--grid {
				#{$archive}__list__item {
					@include make-col(6);

					@include media-breakpoint-down(sm) {
						@include make-col(12);
					}
				}
			}
		}

		+ #{$archive}__noresult {
			padding: $grid-gutter-width;
		}

		+ #{$archive}__list,
		+ #{$archive}__noresult {
			@include make-col(9);

			@include media-breakpoint-down(md) {
				@include make-col(12);
			}
		}
	}

	&--grid {
		padding: calc($section-padding / 2) 0;
	}
}
