.questions {
	&__list {
		@include reset-list;

		&__title {
			margin-top: calc($section-padding / 2);
			padding-bottom: 0.5rem;
			border-bottom: 1px dotted rgba($black, 0.25);
			font-size: 2rem;
			color: $red;
		}

		&--faqs {
			font-family: $secondary-font;
		}

		&--groups {
		}
	}

	&__item {
		&--group {
			display: inline-block;
		}
	}

	&__text {
		//font-weight: normal;
		font-size: 1.2rem;
		margin-top: 2rem;
	}

	&__answer {
	}

	&__departments {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		@include reset-list;
		@include mt(-2rem);
		@include pt(2rem);

		@include media-breakpoint-down(md) {
			//display: block;
		}
	}

	&__department {
		font-family: $secondary-font;
		text-transform: uppercase;
		@include photoshop-to-css-font(28, 50, 2);

		a {
			background-color: rgba($black, 0.075);
			text-decoration: none !important;
			border-radius: 100px;
			padding: 0.25rem 0.5rem;
			display: block;
			line-height: 1;
			margin: 0.25rem 0;
			margin-right: 0.25rem;

			&:hover {
				background-color: rgba($black, 0.1);
			}
		}

		&--intro {
			font-weight: bold;
			margin-right: 0.5rem;
		}
	}

	&__back-to-top {
		font-family: $secondary-font;
		text-transform: uppercase;
		text-decoration: none !important;
		@include photoshop-to-css-font(28, 50, 2);
	}
}
