.video {
	&__thumbnail {
		border-radius: $border-radius;
		overflow: hidden;
		box-shadow: $box-shadow;
		position: relative;

		img {
			display: block;
			position: relative;
			z-index: 1;
			width: 100%;
		}

		&::after {
			content: "\f04b";
			font-size: 4rem;
			color: $white;
			font-family: "Font Awesome 5 Pro";
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 2;
			transition: all 0.2s ease;
			position: absolute;
			@include to-edges;
		}

		&:hover {
			&::after {
				transform: scale(1.1);
			}
		}
	}

	&__article {
		margin-top: calc($grid-gutter-width / 2);
	}

	&__title {
		text-transform: uppercase;
		margin: 0 0 1rem;
		line-height: 1.1em;
		@include photoshop-to-css-font(80, 70, 2);

		@include media-breakpoint-down(md) {
			@include photoshop-to-css-font(68, 70, 2);
		}
	}

	&__summary {
		font-family: $secondary-font;
		text-transform: uppercase;
		@include photoshop-to-css-font(32, 125, 2);

		@include media-breakpoint-down(md) {
			@include photoshop-to-css-font(30, 125, 2);
		}
	}

	&__description {
		line-height: 1.7em;
		@include photoshop-to-css-font(37, 0, 2);
	}

	&__content {
		padding: calc($section-padding / 4);
	}

	&__button {
		margin-top: calc($grid-gutter-width / 2);
	}

	&__lightbox {
		display: none;
	}
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
