.main-nav {
	$title-font-size: 42;
	$link-font-size: 27;
	$px: 3rem;
	$link-px: 0;
	display: flex;
	align-items: center;

	.mega-menu-wrap {
		flex: 1 0;

		ul {
		}
	}

	.mega-menu {
		font-family: $secondary-font;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		position: relative;
		//justify-content: center;
		@include photoshop-to-css-font(28, 75, 2);
		@include reset-list();
		@include ml(-(calc($px / 2)));

		@include media-breakpoint-down(lg) {
			@include photoshop-to-css-font(26, 75, 2);
		}

		@include media-breakpoint-down(md) {
			opacity: 0;
			visibility: hidden;
			transition: all 0.4s ease;
			background-color: darken($white, 5);
			z-index: 100000;
			display: block;
			padding: 1rem;
			position: absolute;
			left: 0;
			right: auto;
		}

		@include media-breakpoint-down(sm) {
			right: 0;
		}

		> .mega-menu-item {
			height: 100%;
			display: flex;
			align-items: center;
			padding: 1em (calc($px / 2) - $link-px);
			text-align: center;
			//position: relative;

			@include media-breakpoint-down(lg) {
				padding: 1em (calc($px / 3) - $link-px);
			}

			@include media-breakpoint-down(md) {
				height: auto;
				display: block;
				max-width: 600px;
				margin: 0 auto;
				text-align: left;
			}

			&.highlight {
				> a {
					color: $red;
					font-weight: bold;
				}
			}

			> a {
				padding: 0.5em ($link-px);
				display: inline-block;
				position: relative;
				text-transform: uppercase;
				font-family: $secondary-font;
				color: $text-color;
				line-height: 1;

				@include media-breakpoint-down(md) {
				}

				&::before,
				&::after {
					content: "";
					position: absolute;
					background-color: $primary-color;
					top: 100%;
					height: 2px;
					transition: all 0.3s ease;
					left: 50%;
					right: 50%;

					@include media-breakpoint-down(md) {
						display: none;
					}
				}

				&::after {
					@include media-breakpoint-down(md) {
						//content: "\f067";
						font-family: "Font Awesome 5 Pro";
						float: right;
						padding: 0 15px;
						position: relative;
						top: 2px;
					}
				}
			}

			// Level 1 - Mega-menu Box
			> .mega-sub-menu {
				flex: 1 0;
				transition: all 0.3s ease;
				position: absolute;
				background-color: $white;
				left: 0;
				right: 0;
				top: 80%;
				opacity: 0;
				visibility: hidden;
				box-shadow: 0 30px 30px rgba($green, 0.15);
				//border-top: 3px solid #ecf3f9;

				@include media-breakpoint-down(md) {
					opacity: 1;
					visibility: visible;
					position: static;
					box-shadow: none;
					display: none;
					background-color: transparent;
				}
			}

			.mega-sub-menu {
				@include media-breakpoint-down(md) {
					//background-color: rgba($black, 0.025);
					padding-left: 0.5rem;
				}
			}

			// Level 3 - Mega-menu Columns
			.mega-sub-menu .mega-sub-menu .mega-sub-menu {
				height: 100%;
			}

			// Level 4 - Mega-menu Sub-menus
			.mega-sub-menu .mega-sub-menu .mega-sub-menu .mega-sub-menu {
				padding-left: 1rem;

				.mega-menu-item {
					display: flex;
					align-items: center;

					> a {
						@include py(0.25rem);
					}

					&::before {
						content: "\f061";
						font-family: "Font Awesome 5 Pro";
						margin-right: 0.25rem;
						font-size: 0.7rem;
					}
				}
			}

			&.mega-toggle-on {
				@include media-breakpoint-down(md) {
					> a {
						margin-bottom: 0.5em;

						&::after {
							content: "\f068";
						}
					}

					.mega-sub-menu {
						display: block;
					}
				}
			}

			// This is the normal drop-down layout
			&.mega-menu-flyout {
				position: relative;
				white-space: nowrap;

				> .mega-sub-menu {
					padding: 15px 0;
					right: auto;
					left: 50%;
					// transform: translateX(-50%);

					@include media-breakpoint-down(md) {
						padding: 0;
						@include pl(1rem);
					}

					a {
						padding: 0;
						@include py(0.25rem);
					}
				}
			}

			// This is the advanced mega-menu layout
			&.mega-menu-grid {
				$offset: 15px;
				position: static;
				white-space: normal;

				> .mega-sub-menu {
					//right: $offset;
					//max-width: 50vw;
					padding: calc($px / 2);

					@include media-breakpoint-down(md) {
						padding: 0;
						//padding: $section-padding / 3;
					}
				}

				.mega-menu-item {
					//position: relative;

					&-type-widget {
						@include media-breakpoint-down(md) {
							display: none;
						}

						&.widget_text {
							.mega-block-title {
								color: $green;
								font-size: 1.2rem;
								margin: 0;
								@include photoshop-to-css-font($title-font-size, 70, 2);
							}
						}

						&.widget_media_image {
							//max-width: 90%;

							a,
							img {
								display: block;
								width: 100%;
								padding: 0;
								display: block;
								border-radius: $border-radius;
							}
						}

						+ .mega-menu-item-type-widget {
							margin-top: 1rem;
						}
					}
				}

				.mega-menu-column {
					@include make-col-ready;

					@for $i from 1 through 12 {
						&.mega-menu-columns-#{ $i }-of-12 {
							@include make-col($i);

							@include media-breakpoint-down(md) {
								@include make-col(12);
							}
						}
					}

					> .mega-sub-menu {
						> .mega-menu-item {
							&.mega-menu-item-has-children {
								+ .mega-menu-item {
									//margin-top: 0.75em;

									@include media-breakpoint-down(md) {
										margin-top: 0;
									}
								}
							}
						}
					}
				}

				.mega-menu-row {
					> .mega-sub-menu {
						@include make-row;
					}
				}
			}

			&:hover,
			&.mega-current-menu-item {
				> a {
					color: $green;
				}
			}

			&:hover {
				> a {
					&::before {
						left: 0;
					}

					&::after {
						right: 0;
					}
				}

				> .mega-sub-menu {
					top: 100%;
					opacity: 1;
					visibility: visible;

					@include media-breakpoint-down(md) {
						//display: block;
					}
				}
			}

			&:first-child {
			}

			&:last-child {
				@include media-breakpoint-down(md) {
					border-bottom: 1px solid rgba($text-color, 0.25);
				}
			}

			a {
				color: $text-color;
				display: block;

				@include media-breakpoint-down(lg) {
					padding-left: calc($spacer / 3);
					padding-right: calc($spacer / 3);
				}

				@include media-breakpoint-down(md) {
					color: $green;
					padding: 0;
				}

				&:hover {
					color: $green !important;
				}
			}

			+ .mega-menu-item {
				@include media-breakpoint-down(md) {
					padding-top: 1em;
					margin-top: 1em;
					border-top: 1px solid rgba($text-color, 0.25);

					@include media-breakpoint-down(md) {
						margin-top: 0;
					}
				}
			}
		}

		.mega-sub-menu {
			text-align: left;
			@include reset-list;

			a {
				padding: 0.5rem 0;
				color: $green;

				@include media-breakpoint-down(md) {
					padding-left: 0;
					padding-right: 0;
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}

		&::after {
			@include media-breakpoint-down(md) {
			}
		}
	}

	.mega-menu-toggle {
		display: none;

		@include media-breakpoint-down(md) {
			display: inline-block;
		}

		.mega-toggle-label {
			cursor: pointer;
			color: $text-color;
			display: inline-block;
			padding: 0.75rem 0;
			border-radius: $border-radius - 1;
			line-height: 1;
			font-family: $secondary-font;
			@include photoshop-to-css-font(28, 75, 2);

			&::before {
				content: "\f0c9";
				font-family: "Font Awesome 5 Pro";
				margin-right: 0.5em;
			}

			.mega-toggle-label-closed {
				display: none;
			}
		}

		&.mega-menu-open {
			+ .mega-menu {
				@include media-breakpoint-down(md) {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	&__search {
		color: $text-color;
		font-size: 0.9em;
		line-height: 1;
		padding: 0.5rem calc($px / 2);
		@include mr(-(calc($px / 2)));
		//@extend .main-nav__menu__item;

		@include media-breakpoint-down(md) {
			padding: 0.5rem;
			@include mr(0);
		}
	}

	&__menu-toggle {
		display: none;
		color: $white;
		text-transform: uppercase;
		font-family: $primary-font;

		i {
			margin-right: 0.5em;
		}

		@include media-breakpoint-down(md) {
			display: inline-block;
		}
	}
}
