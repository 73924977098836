.location {
	$location: &;
	position: relative;

	&--detail {
		#{$location}__map {
			@include make-col-expand;
		}

		#{$location}__offerings {
			@include photoshop-to-css-font(29, 75, 2);
			@include make-col(4);

			@include media-breakpoint-down(md) {
				margin-top: calc($section-padding-md / 2);
				@include make-col(12);
			}
		}
	}
}
