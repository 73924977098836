.btn {
	$self: &;
	$border-width: 2px;
	border: $border-width solid $red;
	font-family: $button-font;
	text-transform: uppercase;
	color: $white;
	line-height: 1;
	text-align: center;
	display: inline-block;
	padding: 15px 25px;
	transition: all 0.3s ease;
	background-color: $red;
	border-radius: calc($border-radius / 2);
	min-width: 255px;
	@include photoshop-to-css-font(30, 130, 2);

	@include media-breakpoint-down(md) {
		padding: 10px 20px;
		@include photoshop-to-css-font(28, 100, 2);
	}

	&:hover {
		color: $white;
		background-color: darken($red, 8);
	}

	&:focus,
	&:active {
		outline: none;
		box-shadow: none;
	}

	@each $label, $map in $color-map {
		&--#{$label} {
			background-color: map-get($map, bg);
			border-color: map-get($map, bg);
			color: map-get($map, color);

			&:hover {
				color: map-get($map, color);
				background-color: map-get($map, hover-bg);
				border-color: map-get($map, hover-bg);
			}

			&#{ $self }--ghost {
				background-color: transparent;
				border-color: map-get($map, bg);
				color: map-get($map, bg);

				&:hover {
					color: map-get($map, hover-color);
					background-color: map-get($map, hover-bg);
					border-color: map-get($map, hover-bg);
				}
			}
		}
	}

	&--narrow {
		@include px(15px);
	}

	&--medium {
		padding: 12px 22px;
		@include photoshop-to-css-font(26, 130, 2);
	}

	&--small {
		padding: 10px;
		@include photoshop-to-css-font(22, 130, 2);
	}

	&--fitted {
		min-width: 0;
	}

	&--block {
		display: block;
		width: 100%;
		min-width: 0;

		& + & {
			margin-top: 0.5rem;
		}
	}

	&[disabled] {
		filter: grayscale(100%);
		opacity: 0.5;
	}
}
