.logo-bar {
	align-items: center;
	padding: 1em 0;
	@include make-row;
	@include flex-wrap(nowrap);

	&__logo {
		$logo-width: 115px;
		@include make-col-ready;
		@include make-col-auto;

		img {
			width: $logo-width;
			display: block;

			@include media-breakpoint-down(lg) {
				width: $logo-width - 10;
			}

			@include media-breakpoint-down(md) {
				width: $logo-width - 20;
			}

			@include media-breakpoint-down(sm) {
				width: $logo-width - 40;
			}
		}

		&--anniversary {
			$logo-width: 225px;
			margin: -0.25rem 0;

			img {
				width: $logo-width;

				@include media-breakpoint-down(lg) {
					width: $logo-width - 10;
				}

				@include media-breakpoint-down(md) {
					width: $logo-width - 30;
				}

				@include media-breakpoint-down(sm) {
					width: $logo-width - 50;
				}
			}
		}
	}

	&__location {
		margin-left: auto;
		@include make-col-ready;
		@include make-col-auto;

		@include media-breakpoint-down(md) {
			@include make-col-expand;
			@include pl(0);
			@include ml(0);
		}
	}

	&__promo-badge {
		@include make-col-ready;
		@include make-col-auto;
	}
}
