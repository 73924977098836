.post-filters {
	$form: ".form";

	&__filter {
		&__title {
			font-family: $secondary-font;
			text-transform: uppercase;
			font-size: 0.9rem;
		}

		&__options {
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			flex-wrap: wrap;
		}

		&__option {
			margin-right: 0.25rem;
			font-family: $secondary-font;

			span {
				cursor: pointer;
				display: block;
				line-height: 1;
				margin: 0;
				padding: 0;
				border-radius: 13px;
				background-color: darken($white, 5);
				color: rgba($text-color, 0.75);
				padding: 5px 10px;
				padding-left: 5px;
				position: relative;
				display: flex;
				align-items: center;
				font-size: 0.9rem;

				i {
					margin-right: 0.5rem;
					position: relative;
					top: 1px;
				}
			}

			input {
				visibility: hidden;
				position: absolute;
				left: -9999px;

				&:checked + span {
					background-color: $green;
					color: #fff;

					i:before {
						content: "\f058";
					}
				}
			}
		}

		& + & {
			margin-top: calc($grid-gutter-width / 2);
		}
	}

	#{$form}__field__label {
		opacity: 0.5;
	}
}

.filter-description {
	font-family: $secondary-font;
	margin-bottom: 1rem;
	font-size: 1.1rem;
	font-style: italic;

	p {
		margin: 0;
	}
}

.post-filters,
.filter-description {
	padding: calc($grid-gutter-width / 1.5);
	background-color: $white;
	box-shadow: $box-shadow;
	border-radius: $border-radius;
}
