.section {
	$section: &;

	&--post-list {
		#{$section}__headline {
			text-align: center;
			@include make-col-ready;
			@include make-col-expand;
		}
	}
}
