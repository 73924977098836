.widget {
	$widget: &;
	$box-padding: 2rem;
	@include make-col-ready;
	@include make-col-base;

	&__hero {
		margin: 0 (-$box-padding);
		margin-top: -$box-padding;
		margin-bottom: $box-padding;
		border-radius: $border-radius $border-radius 0 0;
		height: 20vw;
		min-height: 250px;
		max-height: 400px;
		position: relative;
		@include background-cover;

		&__image {
			height: 100%;
			width: 100%;
			max-width: 100%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 0;

			img {
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}
	}

	&__footer {
		&--enhanced {
			margin: 0 (-$box-padding);
			margin-top: $box-padding;
			margin-bottom: -$box-padding;
			background-color: rgba($black, 0.05);
			padding: $box-padding;
		}
	}

	&__title {
		font-size: 1.7rem;
		font-weight: normal;
		text-transform: uppercase;
		margin: 0 0 0.5em;
		line-height: 1;

		@include media-breakpoint-down(md) {
			font-size: 1.5rem;
		}

		&__icon {
			margin-right: 0.5em;
			position: relative;
			top: 2px;
		}

		&__text {
			line-height: 1;
		}

		& + #{$widget}__list--checked {
			margin-top: 1.5rem;
		}
	}

	&__description {
		text-transform: none;
		margin: 0;
		line-height: 1.6em;

		&--loud {
			font-size: 1.25rem;

			@include media-breakpoint-down(md) {
				font-size: 1.1rem;
			}
		}

		& + & {
			margin-top: 1rem;
		}

		p {
			margin: 0;

			+ p {
				margin-top: 1rem;
			}
		}
	}

	&__thumbnail {
		width: 45px;
	}

	&__caption {
		margin-left: 0.5rem;
		@include photoshop-to-css-font(30, 100, 2);
	}

	&__list {
		$list: &;
		margin-top: 2em;
		font-family: $secondary-font;
		text-transform: uppercase;
		@include photoshop-to-css-font(29, 75, 2);
		@include reset-list;

		&__item {
			position: relative;
			//margin-top: 1em;
			line-height: 1;

			& + & {
				margin-top: 1.5em;
			}
		}

		&__label {
			display: block;
			font-weight: 700;
			opacity: 0.5;
			font-size: 0.9em;
		}

		&__value {
			font-size: 1.2em;
			font-weight: 400;
			line-height: 1.4em;
		}

		&--checked {
			#{$list}__item {
				padding-left: 1.5em;

				a {
					color: $text-color;

					&:hover {
						color: $red;
					}
				}

				& + #{$list}__item {
					margin-top: 1em;
				}

				&::before {
					content: "\f00c";
					font-family: "Font Awesome 5 Pro";
					font-weight: 400;
					color: #77a75b;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}

		&--icons {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			flex-wrap: wrap;

			#{$list}__item {
				text-align: center;
				padding: 0 calc($section-padding / 4);

				i {
					font-size: 3rem;
					line-height: 1;
					margin: 0 0 0.5rem;
				}
			}
		}

		&--post-link {
			#{$list}__item {
				padding: 0.75rem 0;
				display: flex;
				align-items: center;
				border-bottom: 1px dotted rgba($black, 0.25);

				& + #{$list}__item {
					margin: 0;
				}

				&:first-child {
					border-top: 1px dotted rgba($black, 0.25);
				}
			}
		}
	}

	&__stat-value {
		font-family: $secondary-font;
		margin: 1.25em 0;
	}

	&__box {
		box-shadow: $box-shadow;
		background-color: #fff;
		border-radius: $border-radius;
		padding: $box-padding;
		height: 100%;
		overflow: hidden;

		&--map {
			height: 100%;
			min-height: 300px;
			width: 100%;
			overflow: hidden;
			border: 8px solid #fff;
			padding: 0;
		}
	}

	&--stat {
		text-align: center;
		margin-bottom: 28.75px;
		position: relative;

		#{$widget}__title {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		@for $i from 0 to 6 {
			&:nth-child(#{$i}) {
				animation-delay: ($i - 1) * 0.25s;
			}
		}

		a:not(.btn) {
			color: $text-color;
			text-decoration: underline;
		}
	}
}
