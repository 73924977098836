.promo-badge {
	text-align: center;
	margin: -0.75rem 0;
	margin-left: 1rem;

	@include media-breakpoint-down(md) {
		margin: -1.75rem 0;
	}

	&__image {
		width: 120px;

		@include media-breakpoint-down(md) {
			width: 100px;
		}

		@include media-breakpoint-down(sm) {
			width: 90px;
		}
	}

	&__tag {
		font-family: $secondary-font;
		margin: 0;
		color: $text-color;
		font-size: 0.9rem;
		text-transform: uppercase;

		@include media-breakpoint-down(lg) {
			font-size: 0.8rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 0.7rem;
		}
	}
}
