$section-name: "event";

.#{$section-name} {
	$self: &;
	position: relative;

	&__map {
		@include make-col-ready;
		@include make-col(8);
	}

	&__info {
		@include make-col-ready;
		@include make-col(4);
	}
}

.section__#{$section-name} {
	padding: calc($section-padding / 2) 0;
}
