.event-list {
	$event-list: &;

	&__calendar {
		background-color: $white;
		padding: 2em;
		border-radius: $border-radius;
		font-family: $secondary-font;
		position: relative;

		#{$event-list}__loading {
			border-radius: $border-radius;
			text-align: center;
			background-color: rgba(darken($beige, 10), 0.75);
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 1;
			visibility: visible;
			transition: all 0.4s ease;
			margin: 0;
			font-size: 1.4rem;
			position: absolute;
			z-index: 10000;
			@include to-edges;

			i {
				margin-right: 0.5rem;
			}

			&.js-hide {
				opacity: 0;
				visibility: hidden;
			}
		}

		.fc-event {
			background-color: $red;
			border-color: $red;
			font-size: 0.8rem;
			line-height: 1;
			padding: 0.25em 0.5em;
			color: $white;

			.fc-title {
				display: flex;

				&::before {
					content: "\f08e";
					font-family: "Font Awesome 5 Pro";
					margin-right: 5px;
				}
			}

			.fc-content {
				white-space: normal;
			}

			&:not([href]) {
				cursor: default;
				color: $white;

				&:hover {
					color: $white;
				}
			}

			&.event-type-garden-tips {
				.fc-title {
					&::before {
						content: "\f4cb";
					}
				}
			}
		}

		.fc-scroller {
			overflow: visible !important;
			height: auto !important;
		}
	}

	&__types {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		@include reset-list;
		margin-bottom: 0.5rem;

		&__title {
			font-family: $secondary-font;
			font-weight: bold;
			text-transform: uppercase;
			margin-right: 0.5rem;
			margin-bottom: 0.25rem;
			font-size: 0.8em;
			display: block;
			width: 100%;
			color: rgba($text-color, 0.35);
		}
	}

	&__type {
		$key-size: 0.75rem;
		margin-right: calc($key-size / 2);
		font-family: $secondary-font;
		font-size: 0.8rem;
		text-transform: uppercase;

		&__label {
			margin: 0;
		}

		&__name {
			cursor: pointer;
			display: flex;
			align-items: center;
			opacity: 0.45;
			user-select: none;
			line-height: 1;
			padding: 0.25rem;
			padding-right: 0.5rem;
			border: 1px solid rgba($text-color, 0.2);
			background-color: $white;
			border-radius: 100px;

			&:hover {
				border-color: $text-color;
			}
		}

		&__key {
			height: $key-size;
			width: $key-size;
			display: inline-block;
			border-radius: 100%;
			margin-right: calc($key-size / 3);
			background-color: $red;
		}

		&__checkbox {
			opacity: 0;
			text-indent: -9999px;
			position: absolute;
		}

		&__checkbox:checked + &__name {
			opacity: 1;
		}
	}
}
