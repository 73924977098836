.search-did-you-mean {
	padding: 1rem;
}

.search-pagination-container {
	padding: 1rem;
}

.search-list-pagination {
	button {
		background-color: transparent;
		border: 1px solid #77a75b;
		border-radius: 3px;
		color: #77a75b;

		&.jplist-selected {
			background-color: #77a75b;
			color: #FFFFFF;
		}
	}
	.jplist-holder {
		display: inline-block;

		button {
			margin-left: 4px;

			&:first-child {
				margin-left: 0px;
			}
		}

	}
}
