.section {
	$section: &;

	&--plant-directory {
		#{$section}__headline {
			text-align: center;
			@include make-col-ready();
			@include make-col(12);
			@include mx(auto);
		}
	}
}
