.location {
	$location: &;

	@include media-breakpoint-up(lg) {
		@include make-row;
	}

	@include media-breakpoint-down(sm) {
		border-left: 1px solid rgba($black, 0.15);
		padding-left: 0.5rem;
	}

	&__detail {
		$stat: &;
		display: flex;
		align-items: center;
		margin-left: 1vw;

		@include media-breakpoint-down(lg) {
			//margin-left: 1.5vw;
		}

		@include media-breakpoint-down(md) {
			margin-left: 0.5vw;
			align-items: flex-start;
		}

		@include media-breakpoint-up(lg) {
			@include make-col-ready;
			@include make-col-auto;
			@include pl(0);
		}

		@include media-breakpoint-down(sm) {
			line-height: 1;
		}

		&__icon {
			font-size: 1.8rem;
			margin-right: 0.5rem;
			line-height: 1;

			@include media-breakpoint-down(md) {
				font-size: 1.25rem;
				margin-right: 0.25rem;
				display: none;
			}
		}

		&__value,
		&__label {
			margin: 0;
			font-family: $secondary-font;
			text-transform: uppercase;
			@include photoshop-to-css-font(30, 75, 2);

			@include media-breakpoint-down(lg) {
				@include photoshop-to-css-font(26, 75, 2);
			}

			@include media-breakpoint-down(md) {
				@include photoshop-to-css-font(24, 75, 2);
			}

			@include media-breakpoint-down(md) {
				display: inline;
				line-height: 1.2em;
				//@include photoshop-to-css-font(30, 75, 2);
			}
		}

		&__value {
			font-weight: 600;

			a {
				color: $text-color;
			}
		}

		&__label {
			line-height: 1;
			margin-top: 0.25rem;

			&,
			a {
				color: $primary-color;
			}

			a {
				@include media-breakpoint-down(sm) {
					&::before {
						font-family: "Font Awesome 5 Pro";
						font-size: 0.9rem;
						font-weight: 300;
					}
				}
			}
		}

		&--locator {
			#{$stat}__label {
				a::before {
					@include media-breakpoint-down(sm) {
						content: "\f3c5";
					}
				}
			}
		}
	}

	&__loading {
		margin: 0;

		@include media-breakpoint-down(sm) {
			font-size: 1rem;
		}
	}

	&--set {
		#{$location}__detail {
			$stat: &;

			&__label {
				@include media-breakpoint-down(sm) {
					font-size: 0;
				}
			}

			&--locator {
				#{$stat}__label a::before {
					@include media-breakpoint-down(sm) {
						content: "\f044";
					}
				}
			}

			&--phone {
				#{$stat}__label a::before {
					@include media-breakpoint-down(sm) {
						content: "\f5a0";
					}
				}
			}

			&--hours {
				#{$stat}__label a::before {
					@include media-breakpoint-down(sm) {
						content: "\f017";
					}
				}
			}
		}
	}
}
