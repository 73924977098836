.steps {
	&__list {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		@include reset-list;
		@include make-row;
	}

	&__item {
		@include make-col-ready;
		@include make-col(4);
		@include py(1rem);
		@include px(3rem);

		@for $i from 1 to 10 {
			&:nth-child(#{$i}) {
				animation-delay: $i * 0.25s;
			}
		}

		@include media-breakpoint-down(md) {
			@include make-col(6);
			@include px(1.5rem);
		}

		@include media-breakpoint-down(sm) {
			@include px(0.5rem);
		}
	}

	&__icon {
		margin-bottom: 0.5em;
		text-align: center;

		svg {
			display: block;
			max-height: 60px;
			margin: 0 auto 0.5em;

			path {
				fill: $red;
			}
		}

		label {
			margin: 0;
			font-family: $secondary-font;
			text-transform: uppercase;
			@include photoshop-to-css-font(32, 125, 2);

			@include media-breakpoint-down(sm) {
				@include photoshop-to-css-font(28, 125, 2);
			}
		}
	}

	&__title {
		text-transform: uppercase;
		line-height: 1.2em;
		@include photoshop-to-css-font(60, 70, 2);

		@include media-breakpoint-down(sm) {
			@include photoshop-to-css-font(48, 70, 2);
		}
	}

	&__description {
		@include photoshop-to-css-font(37, 0, 2);

		@include media-breakpoint-down(sm) {
			@include photoshop-to-css-font(32, 0, 2);
		}
	}
}
