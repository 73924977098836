.plant {
	$plant: &;
	position: relative;

	&--detail {
		#{$plant}__info {
		}

		#{$plant}__description {
			@include make-col-ready();
			@include make-col-base;

			@include media-breakpoint-down(md) {
				@include make-col(12);
			}
		}

		#{$plant}__details {
			@include make-col-ready;
			@include make-col(4);

			@include media-breakpoint-down(md) {
				@include make-col(12);
			}

			.widget {
				@include media-breakpoint-down(md) {
					margin-top: $section-padding-md;
					@include px(0);
				}

				+ .widget {
					margin-top: calc($section-padding / 4);
				}
			}
		}

		.widget__box {
			height: 100%;
			overflow: visible;
		}
	}
}
