.breadcrumbs {
	font-family: $secondary-font;
	font-weight: 600;
	text-transform: uppercase;
	border-bottom: 1px solid $border-color;
	border-top: 1px solid $border-color;
	background-color: $white;
	@include photoshop-to-css-font(25, 75, 2);

	@include media-breakpoint-down(md) {
		text-align: center;
		@include photoshop-to-css-font(23, 75, 2);
	}

	&__list {
		display: flex;
		@include reset-list;
		@include make-col-ready;
		@include make-col-expand;

		@include media-breakpoint-down(md) {
			justify-content: center;
			flex-wrap: wrap;
			padding: 0.25rem 0;
		}
	}

	&__item {
		color: $green;
		padding: 1.25rem 0;

		@include media-breakpoint-down(md) {
			padding: 0.5rem 0;
			margin: 0 1rem;
		}

		a {
			color: $text-color;

			&:hover {
				text-decoration: underline;
			}
		}

		& + & {
			margin-left: 3em;

			@include media-breakpoint-down(md) {
				margin-left: 1rem;
			}
		}
	}
}
