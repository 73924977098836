.section {
	$section: &;

	&__title,
	&__subtitle {
		position: relative;
		z-index: 2;
	}

	&__title {
		margin: 0;
		line-height: 1.2em;
		text-transform: uppercase;
		position: relative;
		z-index: 2;
		@include photoshop-to-css-font(85, 70, 2);

		@include media-breakpoint-down(md) {
			@include photoshop-to-css-font(100, 10, 2);
		}

		@include media-breakpoint-down(sm) {
			@include photoshop-to-css-font(65, 10, 2);
		}
	}

	&__subtitle {
		margin: 0;
		text-transform: uppercase;
		font-family: $secondary-font;
		@include photoshop-to-css-font(32, 125, 2);

		@include media-breakpoint-down(sm) {
			@include photoshop-to-css-font(28, 200, 2);
		}
	}

	&__headline {
		position: relative;
		margin-bottom: 2em;
		transform-style: preserve-3d;

		&::after {
			content: attr(data-watermark);
			display: flex;
			align-items: center;
			justify-content: center;
			color: darken($beige, 3);
			font-family: $accent-font;
			font-size: 9vw;
			line-height: 1;
			position: absolute;
			z-index: 1;
			@include to-edges;
			//@include photoshop-to-css-font(300, 0, 2);
		}

		&--script {
			#{$section}__title {
				font-family: $accent-font;
				text-transform: none;
				line-height: 0.9em;
				@include photoshop-to-css-font(140, 0, 2);

				&::after {
					display: none;
				}
			}
		}
	}

	@each $label, $map in $color-map {
		&--bg-#{$label} {
			#{$section}__headline {
				&::after {
					color: darken(map-get($map, bg), 3);
				}
			}
		}
	}
}
