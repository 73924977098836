.section {
	$section: &;

	&--basic {
		#{$section}__headline {
			@include make-col-ready();
			@include make-col(12);
		}

		#{$section}__article {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-down(md) {
				@include make-col(12);
			}
		}
	}
}
