.searchbox {
	&__suggestion {
		cursor: pointer;
		padding: 0.75em;
		display: flex;
		align-items: center;

		&__label {
			margin: 0;
		}

		&__type {
			margin-left: auto;
		}
	}

	&__input {
		@include make-col-ready;
		@include make-col-expand;
		@extend .form__field__input;

		input[type="text"] {
			$icon-size: 1em;
			background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='#{$icon-size}' height='#{$icon-size}' viewBox='0 0 24 24'><path d='M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z' fill='#{str-replace(#{$text-color}, '#', '')}' /></svg>");
			background-repeat: no-repeat;
			background-position: left $icon-size top 50%;
			padding-left: $icon-size * 3 !important;
			line-height: $input-height;
			height: $input-height;
		}
	}

	&__button {
		@include make-col-ready;
		@include make-col-auto;
		@include pl(0);
		@extend .form__button;
	}

	&__form {
		@include make-row;
	}
}
