.container {
	margin: 0 auto;
	position: relative;
	//z-index: 10;
	@include make-container;
	@include make-container-max-widths;

	@include media-breakpoint-down(md) {
		padding: 0 25px;
	}
}

.box {
	@include make-row;
}

.hidden {
	display: none;
}

.invisible {
	opacity: 0;
}

.animated {
}

.js-no-scroll {
	overflow: hidden;
}

.jump-to-top {
	$offset: 2rem;
	$size: 3rem;
	position: fixed;
	right: $offset;
	bottom: 0;
	width: $size;
	height: $size;
	line-height: $size;
	background-color: $white;
	font-size: 175%;
	box-shadow: 0 0 1rem rgba($black, 0.25);
	text-align: center;
	opacity: 0;
	transition: all 0.3s ease;

	&:hover {
		transform: scale(1.2);
	}

	&--visible {
		opacity: 1;
		bottom: $offset;
	}
}
