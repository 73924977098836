[data-tooltip],
.tooltip {
	$padding: 10px;
	$width: 160px;
	position: relative;
	cursor: pointer;

	&::before,
	&::after {
		position: absolute;
		visibility: hidden;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
		opacity: 0;
		-webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
		-moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
		transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		pointer-events: none;
		bottom: 100%;
		left: 50%;
	}

	&::before {
		content: "";
		z-index: 1001;
		border: 6px solid transparent;
		background: transparent;
		margin-left: -6px;
		margin-bottom: -12px;
		border-top-color: #000;
		border-top-color: hsla(0, 0%, 20%, 0.9);
	}

	&::after {
		content: attr(data-tooltip);
		z-index: 1000;
		padding: $padding ($padding + 2);
		width: $width;
		background-color: #000;
		background-color: hsla(0, 0%, 20%, 0.9);
		color: $white;
		font-size: 0.8rem;
		line-height: 1.4em;
		margin-left: -(calc($width / 2) + ($padding));
	}

	&:hover {
		&::before,
		&::after {
			visibility: visible;
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
			filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
			opacity: 1;
			-webkit-transform: translateY(-12px);
			-moz-transform: translateY(-12px);
			transform: translateY(-12px);
		}
	}
}
