.featherlight {
	.featherlight-content {
		$close-icon-size: 30px;
		max-width: 90%;
		border-radius: $border-radius;
		border: none;
		padding: $close-icon-size;

		.featherlight-close {
			background-color: transparent;
			line-height: $close-icon-size;
			width: $close-icon-size;
			font-size: 1.4rem;
			top: 3px;
			right: 3px;
		}

		.featherlight-previous,
		.featherlight-next {
			background-color: $white;
			top: 50%;
			bottom: auto;
			transform: translateY(-50%);
			text-shadow: none;
			box-shadow: 0 0 10px rgba($black, 0.25);

			> span {
				font-size: 0;
				padding: 1rem;
				line-height: 1;
				display: flex;
				margin: 0;
				position: static;

				&::after {
					font-family: "Font Awesome 5 Pro";
					font-size: 1.2rem;
					color: $text-color;
				}
			}
		}

		.featherlight-previous {
			left: 0;
			right: auto;
			border-radius: 0 calc($border-radius / 2) calc($border-radius / 2) 0;

			> span {
				&::after {
					content: "\f060";
				}
			}
		}

		.featherlight-next {
			left: auto;
			right: 0;
			border-radius: calc($border-radius / 2) 0 0 calc($border-radius / 2);

			> span {
				&::after {
					content: "\f061";
				}
			}
		}
	}

	&.video {
		.featherlight-content {
			padding: 0;
			width: 800px;
			max-width: 100%;
			//overflow: visible;

			.featherlight-close {
				font-size: 2.2rem;
				top: 5px;
				right: 5px;
			}
		}
	}
}
