.radio-show {
	$radio-show: &;

	&--detail {
		#{$radio-show}__transcript {
			order: 1;
			@include make-col-ready;
			@include make-col(6);

			&--short {
				@include make-col(4);

				@include media-breakpoint-down(md) {
					@include mb(calc($section-padding-md / 2));
				}
			}

			&--normal {
				@include media-breakpoint-down(md) {
					order: 12;
					@include mt(calc($section-padding-md / 2));
				}
			}

			@include media-breakpoint-down(md) {
				@include make-col(12);
			}
		}

		#{$radio-show}__embed {
			order: 6;
			min-height: 320px;
			@include make-col-ready;
			@include make-col-expand;

			iframe {
				width: 100%;
				height: 100%;
				display: block;
			}
		}
	}
}
