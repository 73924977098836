.modal {
	$modal: &;
	display: none;

	&__title {
		margin: 0 0 0.75em;
		text-transform: uppercase;
		font-family: $primary-font;
		line-height: 1.2em;
		@include photoshop-to-css-font(50, 70, 2);
	}

	&__subtitle {
		font-family: $secondary-font;
		font-weight: 600;
		text-transform: uppercase;
		color: $red;
		@include photoshop-to-css-font(32, 70, 2);
	}

	&__widget {
		& + & {
			margin-top: 1.5em;
		}
	}

	&__list {
		$list: &;
		@include reset-list;

		&__title {
			color: $red;
			margin: 0 0 0.5rem;
			font-size: 1.5rem;
		}

		&__description {
			font-family: $secondary-font;
			margin: 0;
			font-size: 0.9rem;
		}

		&__item {
			white-space: nowrap;
			line-height: 1;

			& + & {
				margin-top: 1em;
			}

			&--simple {
				& + & {
					margin-top: 0.5em;
				}
			}
		}

		&__label {
			display: block;
			font-weight: bold;
			line-height: 1;
			margin: 0;
		}

		&--locations {
			margin: 1rem 0 0;

			#{$list}__item {
				padding: 1rem 0;
				align-items: center;
				background-color: rgba($black, 0.05);
				border-radius: calc($border-radius / 2);
				white-space: normal;
				@include make-row;
				@include mx(0);
				@include mb(1rem);

				&:first-child {
				}
			}

			#{$list}__headline {
				padding-right: 1rem;
				//margin-bottom: 0.75rem;
				@include make-col-ready;
				@include make-col-expand;

				@include media-breakpoint-down(sm) {
					@include make-col(12);
				}
			}

			#{$list}__description {
				line-height: 1.4em;
			}

			#{$list}__actions {
				white-space: nowrap;
				@include make-col-ready;
				@include make-col-auto;

				@include media-breakpoint-down(sm) {
					margin-top: 0.5rem;
					@include make-col(12);
				}
			}
		}
	}

	&__loading {
		margin: 0;
		margin-top: 1rem;
	}

	&__column {
		@include make-col-ready;
		@include make-col-expand;
	}

	&__box {
		@include make-row;
	}

	&--interstitial {
		width: 650px;
		max-width: 100%;

		#{$modal}__title {
			margin: 0;
		}

		#{$modal}__content {
			padding: 1em 0;
		}
	}
}

.custom-modal {
	$custom-modal: &;
	$close-icon-size: 30px;
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2147483647;
	text-align: center;
	white-space: nowrap;
	cursor: pointer;
	display: block;

	&::before {
		content: "";
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.8);
	}

	&__content {
		position: relative;
		text-align: left;
		vertical-align: middle;
		display: inline-block;
		overflow: auto;
		padding: 25px 25px 0;
		border-bottom: 25px solid transparent;
		margin-left: 5%;
		margin-right: 5%;
		max-height: 95%;
		background: #fff;
		cursor: auto;
		white-space: normal;
		max-width: 90%;
		border-radius: 15px;
		border: none;
		padding: 30px;
	}

	&__close {
		position: absolute;
		background-color: transparent;
		line-height: $close-icon-size;
		width: $close-icon-size;
		font-size: 1.4rem;
		top: 3px;
		right: 3px;
		text-align: center;
	}

	&--locator {
		#{$custom-modal}__content {
			width: 500px;
		}
	}
}
