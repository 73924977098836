@mixin content-section-ready($padding-x: 25px) {
	padding: ($padding-x) 0;
}

@mixin to-edges() {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

@mixin make-col-base() {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}

@mixin make-col-auto() {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

@mixin make-col-expand() {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}

@mixin no-gutters {
	margin: 0 !important;
}

@mixin clearfix() {
	zoom: 1; /* For IE 6/7 (trigger hasLayout) */

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}
}
