.section {
	$section: &;
	padding: $section-padding 0;
	position: relative;
	transform-style: preserve-3d;
	//overflow: hidden;

	@include media-breakpoint-down(sm) {
		padding: $section-padding-sm 0;
	}

	@each $label, $map in $color-map {
		&--bg-#{$label} {
			background-color: map-get($map, bg);

			&:not(.section--bg-image) + &:not(.section--bg-image) {
				padding-top: 0;
			}
		}
	}

	&__article {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			text-transform: uppercase;
		}

		p,
		ul,
		ol {
			line-height: 1.7em;
			@include photoshop-to-css-font(37, 0, 2);

			a:hover {
				text-decoration: underline;
			}
		}

		a:not(.btn) {
			text-decoration: underline;
		}

		.btn {
			+ p {
				margin-top: 1.5em;
			}

			&:last-child {
				margin-top: 1.5em;
			}
		}
	}

	&__bg-image {
		filter: grayscale(75%);
		opacity: 0.1;
		z-index: 0;
		position: absolute;
		@include to-edges;
		@include background-cover;
	}

	&--bg-image {
		& + & {
			border-top: 5px solid $white;
		}

		&:last-child {
			border-bottom: 5px solid $white;
		}
	}

	&--layout-compact {
		padding-top: calc($section-padding / 2);
		padding-bottom: calc($section-padding / 2);

		#{$section}__headline {
			margin-bottom: 0;
		}
	}
}
