.callout {
	$callout: &;
	$offset_width: 2px;
	$offset_color: rgba($white, 0.5);
	display: flex;
	text-align: center;
	position: relative;
	box-shadow: $box-shadow;
	border-radius: $border-radius;
	overflow: hidden;
	transition: transform 0.4s ease, box-shadow 0.4s ease;
	transform-origin: center;
	backface-visibility: hidden;
	transform: scale3d(0.99, 0.99, 1);
	height: 100%;
	background-color: $white;

	@include media-breakpoint-down(md) {
	}

	&__image {
		display: block;
		position: relative;
		height: 12vw;
		@include background-cover;

		@include media-breakpoint-down(md) {
			height: 25vw;
			min-height: 250px;
		}

		@include media-breakpoint-down(sm) {
			height: 8rem;
			min-height: auto;
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: $offset_width;
			background-color: $offset_color;
		}
	}

	&__icon {
		$icon-size: 95px;
		position: absolute;
		padding-bottom: $icon-size;
		width: $icon-size;
		background-color: $white;
		border-radius: 100%;
		margin-top: -(calc($icon-size / 2));
		top: 0;
		left: 50%;
		margin-left: -(calc($icon-size / 2));
		line-height: $icon-size;
		z-index: 1;

		svg {
			$size: 60%;
			//display: none;
			position: absolute;
			left: 50%;
			top: 50%;
			width: $size;
			max-height: $size;
			margin-left: -(calc($size / 2));
			margin-top: -(calc($size / 2));
			z-index: 2;

			path {
				fill: $green;
			}
		}

		&::after {
			content: "";
			border-radius: 100%;
			position: absolute;
			left: -($offset_width);
			top: -($offset_width);
			right: -($offset_width);
			bottom: -($offset_width);
			background-color: rgba($white, 0.5);
			transform: translateZ(-1px);
			z-index: 1;
		}
	}

	&__headline {
		position: relative;
		z-index: 2;
	}

	&__title {
		margin: 0;
		text-transform: uppercase;
		font-family: $primary-font;
		line-height: 1.2em;
		@include photoshop-to-css-font(54, 70, 2);

		@include media-breakpoint-down(md) {
			@include photoshop-to-css-font(46, 70, 2);
		}

		@include media-breakpoint-down(sm) {
			@include photoshop-to-css-font(40, 70, 2);
		}
	}

	&__subtitle {
		margin: 0;
		text-transform: uppercase;
		font-family: $secondary-font;
		@include photoshop-to-css-font(28, 125, 2);

		@include media-breakpoint-down(md) {
			@include photoshop-to-css-font(22, 125, 2);
		}
	}

	&__content {
		position: relative;
		z-index: 2;
		background-color: $white;
		padding: 2.5em 1em;
		flex: 1 0 0;

		@include media-breakpoint-down(md) {
			padding: 1em;
		}
	}

	&__text {
		@include photoshop-to-css-font(34, 25, 2);

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&__button {
		margin-top: $grid-gutter-width;
	}

	&:hover {
		transform: scale3d(1.025, 1.025, 1);
		box-shadow: $box-shadow-hover;
	}

	&--grid {
		flex-direction: column;

		#{$callout}__content {
			padding-bottom: 6.5rem;

			@include media-breakpoint-down(md) {
				padding-top: 3rem;
				padding-bottom: 5.5rem;
			}
		}

		#{$callout}__text {
			margin: 1.5rem 1rem;
			margin-bottom: 0;
		}

		#{$callout}__bottom {
			$offset: 1.5rem;
			position: absolute;
			left: $offset;
			right: $offset;
			bottom: $offset + 0.5;

			@include media-breakpoint-down(md) {
				$offset: 1rem;
				left: $offset;
				right: $offset;
				bottom: $offset + 0.5;
			}
		}
	}

	&--list {
		@include make-row;
		@include mx(0);
		@include flex-wrap(nowrap);

		@include media-breakpoint-down(md) {
			@include flex-wrap(wrap);
		}

		&,
		&:hover {
			transform: scale3d(1, 1, 1);
		}

		#{$callout}__image {
			height: 100%;
			order: 1;
			@include make-col-ready;
			@include make-col(4);

			@include media-breakpoint-down(md) {
				height: auto;
				@include make-col(12);
			}

			&::after {
				content: none;
			}

			&--inline {
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					max-height: 350px;
					//min-height: 300px;
					//max-width: 20vw;
					@include p(2rem);

					@include media-breakpoint-down(md) {
						max-height: 275px;
						@include pb(0);
					}
				}
			}
		}

		#{$callout}__content {
			flex: 1;
			text-align: left;
			order: 6;
			@include make-col-ready;
			@include make-col-expand;

			@include media-breakpoint-down(md) {
				@include make-col(12);
			}
		}

		#{$callout}__headline,
		#{$callout}__text,
		#{$callout}__bottom {
			@include px(calc($section-padding / 3));

			@include media-breakpoint-down(md) {
				@include px(calc($section-padding-md / 2));
			}
		}

		#{$callout}__text {
			margin: 1rem 0 0;
		}
	}

	&--product {
		&#{$callout}--grid {
			#{$callout}__image {
				img {
					object-fit: cover;
					height: 90%;
					padding-top: 5%;
				}
			}
		}
	}

	&--even {
		#{$callout}__image {
			order: 12;

			@include media-breakpoint-down(md) {
				order: 1;
			}
		}
	}
}
