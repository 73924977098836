.form {
	$form: &;
	$field-height: 45px;

	&__field {
		$field: &;
		padding: 0;

		&__label,
		&__sublabel {
			font-family: $secondary-font;
			text-transform: uppercase;
			margin: 0;
		}

		&__label {
			font-weight: bold;
			@include photoshop-to-css-font(30, 75, 2);
		}

		&__sublabel {
			margin: 0.25em 0 0;
			@include photoshop-to-css-font(22, 75, 2);
		}

		&__input {
			textarea,
			select,
			input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
				padding: 0.75em;
				font-family: $secondary-font;
				border-radius: calc($border-radius / 2);
				border: 1px solid rgba($text-color, 0.25);
				width: 100%;
				background-color: $white;
				appearance: none;
				-webkit-appearance: none;

				&:focus {
					border-color: $text-color;
				}

				&:disabled {
					background-color: lighten($text-color, 65);
				}
			}

			select,
			input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
				line-height: $field-height;
				height: $field-height;
				padding: 0 0.75em;
			}

			select {
				$icon-size: 20px;
				background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='#{$icon-size}' height='#{$icon-size}' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='#{str-replace(#{$text-color}, '#', '')}'/></g></svg>");
				background-repeat: no-repeat;
				background-position: right 10px top 50%;
				padding-right: 2.5rem;
			}

			select[multiple] {
				height: auto;
				line-height: normal;
				padding: 0.75em;
			}
		}

		&--small {
			#{$field}__input {
				$field-height: 35px;

				textarea,
				select,
				input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
					padding: 0.75em;
					font-size: 0.9rem;
				}

				select,
				input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
					line-height: $field-height;
					height: $field-height;
					padding: 0 0.75em;
				}

				select {
					background-position: right 10px top 55%;
					padding-right: 2.5rem;
				}
			}

			#{$field}__label {
				font-family: $secondary-font;
				line-height: 1;
				@include photoshop-to-css-font(22, 75, 2);
			}
		}

		&--loud {
		}

		& + & {
			margin-top: 1.5em;
		}
	}

	&__fields {
		@include reset-list;
	}

	&__title {
		margin: calc($section-padding / 2) 0 0 !important;
		padding: 0 0 1rem;

		h2 {
			font-weight: normal;
			@include photoshop-to-css-font(58, 25, 2);
		}
	}

	&__button {
		.btn {
			line-height: $field-height;
			height: $field-height;
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	&__footer {
		margin-top: 1em;
	}

	&--inline {
		display: flex;
		flex-wrap: wrap;

		#{$form}__field {
			+ #{$form}__field {
				margin-top: 0;
				margin-left: 1rem;

				@include media-breakpoint-down(sm) {
					margin-top: 0.5rem;
					margin-left: 0;
				}
			}
		}
	}
}

// Gravity Forms overrides
.gform_wrapper {
	form {
		.gform_body {
			.gform_fields {
				.gfield {
					@extend .form__field;

					.gfield_label {
						@extend .form__field__label;
					}

					&.gsection {
						@extend .form__title;
					}

					&.two-column,
					&.three-column {
						.gfield_radio,
						.gfield_checkbox {
							display: flex;
							flex-wrap: wrap;
						}
					}

					&.two-column {
						.gfield_radio,
						.gfield_checkbox {
							[class*="choice_"] {
								$column-width: 50%;
								flex: 0 0 $column-width;
								max-width: $column-width;
							}
						}
					}

					&.three-column {
						.gfield_radio,
						.gfield_checkbox {
							[class*="choice_"] {
								$column-width: 33.333%;
								flex: 0 0 $column-width;
								max-width: $column-width;

								@include media-breakpoint-down(md) {
									$column-width: 50%;
									flex: 0 0 $column-width;
									max-width: $column-width;
								}
							}
						}
					}
				}

				.ginput_container {
					@extend .form__field__input;

					&:not(.ginput_container_radio):not(.ginput_container_checkbox) {
						label {
							@extend .form__field__sublabel;
						}
					}

					&.ginput_container_radio,
					&.ginput_container_checkbox {
						margin-top: 0;

						[class*="gchoice_"] {
							display: flex;
							align-items: center;

							@include media-breakpoint-down(md) {
							}

							label {
								font-size: 1.2rem;
								margin: 0;
								margin-left: 0.5rem;
								line-height: 1.2em;

								@include media-breakpoint-down(md) {
									font-size: 1.1rem;
								}
							}

							input {
								margin: 0 0 0 1px;
							}

							input:checked + label {
								font-weight: normal;
							}
						}
					}
				}
			}
		}

		.gform_button,
		.gform_previous_button,
		.gform_next_button {
			@extend .btn;
			@extend .btn--fitted;
		}

		.gform_page_footer {
			display: flex;

			.gform_previous_button,
			.gform_next_button {
				@extend .btn--fitted;
			}

			.gform_next_button,
			.gform_button {
				margin-left: auto !important;
			}

			.gform_previous_button {
				@extend .btn--white;
			}
		}

		.gform_footer {
			text-align: center;
		}
	}
}
