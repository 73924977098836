@import "mixins/module";

/// Put custom mixins here ///

@mixin flex-wrap($val) {
	flex-wrap: $val;
}

@mixin mt($val) {
	margin-top: $val;
}

@mixin mb($val) {
	margin-bottom: $val;
}

@mixin ml($val) {
	margin-left: $val;
}

@mixin mr($val) {
	margin-right: $val;
}

@mixin mx($val) {
	margin-left: $val;
	margin-right: $val;
}

@mixin my($val) {
	margin-top: $val;
	margin-bottom: $val;
}

@mixin p($val) {
	padding: $val;
}

@mixin pt($val) {
	padding-top: $val;
}

@mixin pb($val) {
	padding-bottom: $val;
}

@mixin pl($val) {
	padding-left: $val;
}

@mixin pr($val) {
	padding-right: $val;
}

@mixin py($val) {
	padding-top: $val;
	padding-bottom: $val;
}

@mixin px($val) {
	padding-left: $val;
	padding-right: $val;
}
