.section {
	$section: &;

	&--centered {
		text-align: center;

		#{$section}__headline,
		#{$section}__article {
			z-index: 10;
			margin-left: auto;
			margin-right: auto;
			@include make-col-ready();
			@include make-col(8);

			@include media-breakpoint-down(md) {
				@include make-col(12);
			}
		}

		#{$section}__title {
			padding: 0 3rem;

			@include media-breakpoint-down(lg) {
				padding: 0 1.5rem;
			}
		}

		#{$section}__added-content {
			order: 12;
			@include make-col-ready;
			@include make-col(12);
		}
	}
}
