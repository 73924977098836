/* Base Styles */

body {
	font-family: $primary-font;
	font-size: 16px;
	background-color: $background-color;
	color: $text-color;
	-webkit-font-smoothing: antialiased;

	* {
		&:focus,
		&:hover {
			outline: none;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $header-font;
	font-weight: normal;
}

$base-font-size: 1rem;
$heading-scale: 0.25; // amount of px headings grow from h6 to h1
@for $i from 1 through 6 {
	h#{$i} {
		font-size: $base-font-size + $heading-scale * (6 - $i);
	}
}

figure {
	margin: 0;
}

img {
	max-width: 100%;

	&.alignleft {
		float: left;
		margin-right: 20px;
	}

	&.alignright {
		float: right;
		margin-left: 20px;
	}

	&.alignleft,
	&.alignright {
		margin-bottom: 20px;
	}
}

a {
	text-decoration: none;
	color: $primary-color;

	&:hover {
		text-decoration: none;
		color: darken($primary-color, 10);
	}
}

hr {
	margin: 2rem 0;
	border: none;
	border-top: 1px dotted lighten($text-color, 10);
}

html,
body {
	height: 100%;
}

[v-cloak] {
	display: none;
}
