.newsletter {
	&__headline {
		@include make-col-ready;
		@include make-col-auto;
		text-transform: uppercase;

		h4 {
			margin: 0;
			line-height: 1;
			@include photoshop-to-css-font(85, 70, 2);
		}

		h5 {
			margin: 0;
			font-family: $secondary-font;
			@include photoshop-to-css-font(32, 125, 2);
		}
	}

	&__form {
		margin-left: auto;
		@include make-col-ready;
		@include make-col-auto;

		@include media-breakpoint-down(md) {
			margin-top: 1rem;
			@include make-col-expand;
		}
	}

	&__fields {
		display: flex;
		align-items: center;
		@include make-col-ready;
		@include make-col-auto;

		@include media-breakpoint-down(md) {
			display: block;
			@include make-col-expand;
		}

		input[type="email"] {
			flex: 1 0;
			max-width: 100%;
			padding: 16px 25px;
			line-height: 1;
			background-color: darken($beige, 5);
			border: none;
			border-radius: calc($border-radius / 2);
			font-family: $secondary-font;
			text-transform: uppercase;
			margin-right: $grid-gutter-width;
			width: 20vw;
			@include photoshop-to-css-font(30, 130, 2);

			@include media-breakpoint-down(md) {
				flex: none;
				margin: 0 0 1rem;
				width: 100%;
			}
		}
	}

	&__responses {
		@include make-col-ready;
		@include make-col-expand;
	}

	&__response {
		padding: 0.25rem 0 0;

		&::before {
			font-family: "Font Awesome 5 Pro";
			margin-right: 0.25rem;
		}

		&--success {
			color: $green;

			&::before {
				content: "\f00c";
			}
		}

		&--error {
			color: $red;

			&::before {
				content: "\f071";
			}
		}
	}

	.box {
		align-items: center;
	}
}
