/* Masthead */

.masthead {
	$self: &;
	position: relative;
	z-index: 9999;
	background-color: $background-color;

	&__promotion {
		background-color: $white;
		border-bottom: 1px solid rgba($black, 0.1);
		padding: 0.25rem;
	}

	&__logo-bar {
		border-bottom: 1px solid $border-color;
	}

	&__main-nav {
		// /wp-content/themes/petitti-garden-center/assets/src/sass/layouts/_main-nav.scss
		background-color: $white;
	}
}
