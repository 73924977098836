// Define Font Variables
//$crimson: "Crimson Text", serif;
$crimson: "Crimson Pro", serif;
$cabin: "Cabin", sans-serif;
$relation: "relation-two", cursive;
$primary-font: $crimson;
$secondary-font: $cabin;
$button-font: $cabin;
$header-font: $crimson;
$accent-font: $relation;

/*** Color Definition ***/
$white: #fff;
$black: #000;

// Design Colors
$red: #a32b42;
$beige: #f8f1ef;
$green: #77a75b;
$orange: #f9ae63;
$grey: #333239;

// Brand Colors
$brand_green: #0f522d;
$brand_red: #760f24;

// Color Uses
$background-color: $beige;
$primary-color: $green;
$secondary-color: $beige;
$highlight-color: $red;
$text-color: $grey;
$border-color: #e1e0e1;

$color-map: (
	"white": (
		"color": $grey,
		"bg": $white,
		"hover-color": $grey,
		"hover-bg": darken($white, 10),
	),
	"beige": (
		"color": $grey,
		"bg": $beige,
		"hover-color": $grey,
		"hover-bg": darken($beige, 10),
	),
	"red": (
		"color": $white,
		"bg": $red,
		"hover-color": $white,
		"hover-bg": darken($red, 10),
	),
	"green": (
		"color": $white,
		"bg": $green,
		"hover-color": $white,
		"hover-bg": darken($green, 10),
	),
	"orange": (
		"color": $white,
		"bg": $orange,
		"hover-color": $white,
		"hover-bg": darken($orange, 10),
	),
);

// Template Variables
$border-radius: 15px;
$box-shadow: 0 0 30px rgba($black, 0.15);
$box-shadow-hover: 0 0 45px rgba($black, 0.15);
$section-padding: 115px;
$section-padding-md: calc($section-padding / 2);
$section-padding-sm: calc($section-padding / 3);

// Overriding Bootstrap Breakpoints
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
);
