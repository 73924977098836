.promotions {
	@include make-row;

	&__links {
		text-transform: uppercase;
		font-size: 0.8rem;
		font-family: $secondary-font;
		@include make-col-ready;
		@include make-col-auto;

		a {
			color: rgba($text-color, 0.5);

			&:hover {
				color: rgba($text-color, 0.95);
			}
		}
	}
}
