.slider {
	$slider: &;

	.arrow {
		$border-radius: 0.25rem;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background-color: rgba($white, 0.9);
		border: none;
		line-height: 1;
		display: flex;
		align-items: center;
		z-index: 1000;
		padding: 1rem 0.5rem;

		&--next {
			right: 0;
			border-radius: $border-radius 0 0 $border-radius;
		}

		&--prev {
			left: 0;
			border-radius: 0 $border-radius $border-radius 0;
		}
	}

	&--filmstrip {
		#{$slider}__link {
			position: relative;
			flex: 1;
			width: 100%;
			@include background-cover;

			&::after {
				$icon-size: 50px;
				content: "";
				opacity: 0;
				transition: opacity 0.4s ease;
				background-color: rgba($black, 0.6);
				background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='#{$icon-size}' height='#{$icon-size}' viewBox='0 0 24 24' fill-rule='evenodd' clip-rule='evenodd'><path d='M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5zm-4.5 8h4v-4h1v4h4v1h-4v4h-1v-4h-4v-1z' fill='white' /></svg>");
				background-repeat: no-repeat;
				background-position: center;
				position: absolute;
				z-index: 1001;
				@include to-edges;
			}

			&:hover {
				&::after {
					opacity: 1;
				}
			}
		}

		#{$slider}__item {
			$border-width: 4px;
			//margin: 0 3px;
			border: $border-width solid $white;
			border-left: calc($border-width / 2) solid $white;
			border-right: calc($border-width / 2) solid $white;
			height: 25vh !important;
			min-height: 250px;
		}

		.slick-track {
			display: flex;

			.slick-slide {
				display: flex;
				height: auto;
				align-items: center; //optional
				justify-content: center; //optional
				flex-direction: column;
				float: none;
			}
		}
	}
}
