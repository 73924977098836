.section--callouts {
	&__list {
		display: flex;
		@include reset-list;
		@include make-row;

		&__item {
			@include make-col-ready;
			@include make-col-expand;

			@for $i from 0 to 6 {
				&:nth-child(#{$i}) {
					animation-delay: ($i - 1) * 0.2s;
				}
			}

			@include media-breakpoint-down(md) {
				@include make-col(12);
			}

			& + & {
				@include media-breakpoint-down(md) {
					@include mt(calc($section-padding-md / 2));
				}
			}
		}
	}
}
